@import "./variables.less";

.project-page {
    min-height: 100vh;
    @media (min-width: @md) {
        margin-top: 100px;
    }
    @media (max-width: @md) {
        margin-top: 80px;
    }

    .row1 {
        @media (min-width: @md) {
            overflow: hidden;
            border: solid #384c71 1px;
            margin: 0 5%;
            width: 90%;
            .col {
                cursor: pointer;
                border-left: solid #384c71 1px;
                p {
                    color: #495061;
                    margin: 6px;
                    font-size: 18px;
                }
            }
        }

        @media (max-width: @md) {
            margin: 0 10%;
            width: 80%;
            select {
                font-size: 24px;
                padding: 9px;
            }
        }
    }

    .row2 {
        margin: 0 5% 60px 5%;
        width: 90%;
        .work-class {
            width: 100%;
            height: 300px;

            .description {
                position: relative;
                bottom: 0;
                z-index: 2;
                top: 300px;
                color: black;
                width: 100%;
                background-color: rgba(255, 255, 255, 0.6);
                transition: background-color 0.3s ease-in-out;
            }

            img {
                cursor: pointer;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .work-class:hover {
            .description {
                color: white;
                background-color: rgba(0, 0, 0, 0.6);
            }
        }
    }
}
