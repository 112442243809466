@import "./variables.less";

.footer {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @theme-color;
    p {
        color: white;
        font-size: 12px;
    }
}
