@import "./variables.less";

.contact-page {
    .bg {
        background-image: url(/images/contactbg.png);
        min-height: 100vh;

        .container-outer {
            width: 100%;
            margin: 0;

            .col {
                padding: 0;
            }

            .container {
                z-index: 2;
                position: relative;
                @media (max-width: @md) {
                    padding: 72px 60px;
                    margin: 0;
                    border-radius: 18px;
                }
                @media (min-width: @md) {
                    padding: 60px 120px;
                    margin: 25vh 0;
                    border-radius: 18px;
                }
                top: 0;
                width: 100%;
                background-color: white;

                img {
                    position: relative;
                    width: 100%;
                    background-color: TRANSPARENT;
                    border-radius: 18px;
                    @media (max-width: @md) {
                        margin-bottom: 36px;
                    }
                }

                .right {
                    .info-row {
                        margin-top: 36px;
                        p {
                            margin: 0;
                        }
                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }

                .map {
                    object-fit: contain;
                }
            }
        }
    }
}
