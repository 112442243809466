.gallary {
    display: flex;
    align-content: center;
    justify-content: center;
    z-index: 4;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);

    /** dont select the backdrop*/
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

    p {
        cursor: pointer;
        color: white;
        font-size: 36px;
        line-height: 100vh;
    }

    img {
        padding: 10vh 32px;
        max-height: 80vh;
        max-width: 60%;
        object-fit: contain;
        filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3)) drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    }
}
