@import "./variables.less";

.landing-page {
    @media (min-width: @md) {
        .row1 {
            height: 100vh;
            margin: 0;
            width: 100%;
            overflow: hidden;

            .col1 {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .buttons {
                    margin-top: 30vh;
                    color: #6d6d6f;
                    p {
                        font-weight: lighter;
                        cursor: pointer;
                        border-bottom: solid transparent 2px;
                        font-size: 40px;
                        transition: border-bottom 0.3s;
                    }

                    p:hover {
                        border-bottom: solid #6d6d6f 2px;
                    }
                }
            }

            .col2 {
                .logo-outer {
                    position: absolute;
                    top: 20vh;
                    right: 10%;
                    width: 20%;
                    background-color: rgba(0, 0, 0, 0.3);
                    padding: 80px;
                    img {
                        width: 100%;
                    }
                }
                .landingbg {
                    img {
                        width: 100%;
                        height: 100vh;
                    }
                }
            }
        }

        .row2 {
            margin: 64px 0;
            width: 100%;

            .col1 {
                padding: 0 5%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 100%;
                    box-shadow: 10px 10px 10px 3px rgba(0, 0, 0, 0.3);
                }
            }

            .col2 {
                padding: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                .text {
                    color: #6d6d6f;
                    h2 {
                        font-weight: bold;
                        font-size: 40px;
                    }
                    p {
                        font-weight: lighter;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    @media (max-width: @md) {
        .row1 {
            width: 100%;
            margin: 0;
            height: 100vh;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/images/landingbg.jpg);
            background-position: center;
            background-size: cover;
            .landing-content {
                width: 60%;
                .buttons {
                    margin-top: 10vh;
                    line-height: 48px;
                    font-size: 24px;
                    color: white;
                    text-align: center;
                }
            }
        }

        .row2 {
            width: 100%;
            margin: 0;
            img {
                height: 360px;
                max-width: 100%;
            }
            .text {
                padding: 72px;
                color: #6d6d6f;
                h2 {
                    font-weight: bold;
                    font-size: 36px;
                }
                p {
                    font-weight: lighter;
                    font-size: 18px;
                }
            }
        }
    }

    .row3 {
        width: 100%;

        .row-classes {
            width: 90%;
            margin: 0 5%;

            .work-class {
                position: relative;
                margin: 5vh 5%;
                width: 90%;
                height: 320px;
                background-size: cover;
                background-position: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: bottom;
                }

                .overlay {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    transition: 0.5s ease;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    p {
                        font-size: 36px;
                        color: white;
                    }
                }

                @media (min-width: @md) {
                    .overlay {
                        background-color: rgba(0, 0, 0, 0.6);
                        opacity: 0;
                        transition: 0.5s ease;
                    }

                    .overlay:hover {
                        opacity: 1;
                    }
                }

                @media (max-width: @md) {
                    .overlay {
                        background-color: rgba(0, 0, 0, 0.4);
                        opacity: 1;
                    }
                }
            }
        }
    }
}
