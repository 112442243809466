html,
body {
    margin: 0;
    padding: 0;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loaded {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.loading {
    opacity: 0;
}

a {
    text-decoration: none;
    color: inherit;
}
